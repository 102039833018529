<template>
  <div>
    <v-row align="center">
      <v-col
        cols="3"
        class="text-start"
      >
        <v-text-field
          v-model="applyPackagingSlipNumber"
          label="Packaging Slip#"
          placeholder="Enter Packaging Slip#"
          class="ml-2"
        >
          <template #append-outer>
            <v-btn
              color="primary"
              outlined
              small
              @click="applyPackagingSlipNumberToAll"
            >
              APPLY
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="text-end">
        <v-btn
          class="mr-2"
          color="primary"
          outlined
          @click="toggleSelectAll"
        >
          {{ allItemsSelected ? 'Unselect All' : 'Select All' }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-model="selectedItemsReceived"
      :headers="headers"
      :items="itemsToBeReceived"
      hide-default-footer
      :items-per-page="-1"
      :loading="loading"
      dense
      show-select
    >
      <template #[`item.history.to_item.name`]="{ item }">
        {{
          item.history && item.history.to_item
            ? `${item.history.to_item.material_id} - ${item.history.to_item.name}`
            : ''
        }}
      </template>
      <template #[`item.glass_paint_code`]="{ item }">
        {{
          item.history && item.history.to_glass_paint_code
            ? `${item.history.to_glass_paint_code.code} - ${item.history.to_glass_paint_code.description}`
            : ''
        }}
      </template>
      <template #[`item.fritted`]="{ item }">
        {{ item.is_fritted !== null && item.is_fritted === 1 ? 'Fritted' : 'Non-Fritted' }}
      </template>
      <template #[`item.quantity_to_be_received`]="{ item }">
        <v-text-field
          v-model="item.quantity_to_be_received"
          label="Qty to be Received"
          placeholder="Qty to be Received"
        />
      </template>
      <template #[`item.packaging_slip`]="{ item }">
        <v-text-field
          v-model="item.packaging_slip"
          label="Packing Slip#"
          placeholder="Packing Slip#"
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          class="ml-1 mt-1 mb-1"
          color="primary"
          outlined
          small
          @click="toggleSelection(item)"
        >
          {{ isSelected(item) ? 'Unselect' : 'Select' }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TransactionsApi from '@/api/TransactionsApi';

export default {
  props: {
    poItems: {
      type: Array,
      default: () => [],
    },
    inventoryClassId: {
      type: Number,
      default: null,
    },
    isRepaint: {
      type: Boolean,
      default: false,
    },
    inventoryPoId: {
      type: Number,
      default: null,
    }
  },
  data: () => ({
    loading: false,
    itemsToBeReceived: [],
    selectedItemsReceived: [],
    applyPackagingSlipNumber: null,
    inventoryClassId4500: 1,
    inventoryClassId4800: 2,
    inventoryClassIdInst: 4,
    inventoryClassIdGlass: 5,
    inventoryClassIdComm: 6,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdSid: 9,
    inventoryClassIdPanelParts: 10,
    inventoryClassIdPart: 11,
    inventoryClassIdPaint: 12,
    inventoryClassIdPatioDoor: 14,
    historyModuleReceivingQrId: 3,
    historyTypeCreateId: 1,
    historyTypeUpdateId: 2,
    historyTypeReplenishId: 6,
    historyRecordTypeQrCodeId: 1,
    historyRecordTypeUpcCodeId: 2,
    uomIdSheet: 18, 
    allItemsSelected: false,
  }),
  computed: {
    isUpcCode() {
      return this.inventoryClassId === this.inventoryClassIdPart || this.inventoryClassId === this.inventoryClassIdInst;
    },
    isPaintClass() {
      return this.inventoryClassId === this.inventoryClassIdPaint;
    },
    isGlassClass() {
      return this.inventoryClassId === this.inventoryClassIdGlass;
    },
    headers() {
      const defaultHeaders = [];

      defaultHeaders.push({
        text: 'QR Code',
        value: this.isUpcCode || this.isPaintClass ? 'history.inventory_tag' : 'inventory_tag',
      });

      if (!this.isPaintClass) {
        defaultHeaders.push({
          text: 'Item',
          value: this.isUpcCode ? 'history.to_item.name' : 'item_full_name',
        });
      }

      if (this.isPaintClass) {
        defaultHeaders.push({
          text: 'Item',
          value: 'glass_paint_code',
        });
      }

      if (
        this.inventoryClassId !== this.inventoryClassIdSid
        && this.inventoryClassId !== this.inventoryClassIdGlass
        && this.inventoryClassId !== this.inventoryClassIdVWDGlass
        && this.inventoryClassId !== this.inventoryClassIdPaint
        && this.inventoryClassId !== this.inventoryClassIdPatioDoor
      ) {
        defaultHeaders.push({
          text: 'Colour',
          value: this.isUpcCode ? 'history.to_color.code' : 'color_code',
        });
      }

      if (
        this.inventoryClassId !== this.inventoryClassIdSid
        && this.inventoryClassId !== this.inventoryClassIdGlass
        && this.inventoryClassId !== this.inventoryClassIdVWDGlass
        && this.inventoryClassId !== this.inventoryClassIdPanelParts
        && this.inventoryClassId !== this.inventoryClassIdPaint
        && this.inventoryClassId !== this.inventoryClassIdPatioDoor
      ) {
        defaultHeaders.push({
          text: 'Length',
          value: this.isUpcCode ? 'history.to_length.name' : 'length_name',
        });
      }

      if (this.inventoryClassId !== this.inventoryClassIdPaint) {
        defaultHeaders.push({
          text: 'UOM',
          value: this.isUpcCode || this.isPaintClass ? 'history.to_uom.name' : 'uom_name',
        });
      }

      if (this.inventoryClassId === this.inventoryClassIdPatioDoor) {
        defaultHeaders.push({ text: 'Interior Colors', value: 'color.code' });
        defaultHeaders.push({ text: 'Exterior Colors', value: 'ex_color.code' });
        defaultHeaders.push({ text: 'Door Height', value: 'height' });
        defaultHeaders.push({ text: 'Door Width', value: 'width' });
        defaultHeaders.push({ text: 'Fritted', value: 'fritted' });
        defaultHeaders.push({ text: 'Screen', value: 'door_screen.name' });
      }

      defaultHeaders.push({
        text: 'Location',
        value: this.isUpcCode || this.isPaintClass ? 'history.to_location.name' : 'location_name',
      });

      defaultHeaders.push({
        text: 'Shelf',
        value: this.isUpcCode || this.isPaintClass ? 'history.to_inventory_location.name' : 'inventory_location_name',
      });

      if (!this.isUpcCode && !this.isPaintClass) {
        defaultHeaders.push({
          text: 'Qty On Hand',
          value: 'quantity_on_hand',
        });
      }

      defaultHeaders.push({
        text: 'Qty Received',
        value: this.isUpcCode || this.isPaintClass ? 'history.quantity_added' : 'quantity',
      });

      defaultHeaders.push({
        text: 'Qty to be Received',
        value: 'quantity_to_be_received',
      });

      defaultHeaders.push({
        text: 'Packing Slip#',
        value: 'packaging_slip',
      });

      defaultHeaders.push({
        text: 'PO#',
        value: this.isUpcCode || this.isPaintClass ? 'history.to_po_number' : 'po_number',
      });

      defaultHeaders.push({
        text: 'Received By',
        value: this.isUpcCode || this.isPaintClass ? 'history.created_by_name' : 'created_by_name',
      });

      defaultHeaders.push({
        text: 'Received At',
        value: this.isUpcCode || this.isPaintClass ? 'history.created_at' : 'created_at',
      });

      defaultHeaders.push({ text: 'Actions', value: 'actions', align: 'end' });

      return defaultHeaders;
    },
  },
  watch: {
    poItems: {
      async handler(newItems) {
        if (newItems.length > 0) {
          this.itemsToBeReceived = [];
          this.loading = true;
          const tempItemsToBeReceived = [];
          for (let i = 0; i < newItems.length; i++) {
            const items = await this.getReceivedGoods(newItems[i], i);
            tempItemsToBeReceived.push(...items);
          }
          this.itemsToBeReceived = tempItemsToBeReceived;
          this.itemsToBeReceived = this.removeDuplicates(newItems);
          this.loading = false;
        }
      },
      immediate: true,
      deep: true,
    },
    selectedItemsReceived(newItems) {
      this.$emit('updateSubmitButtonState', newItems.length > 0);
    },
  },
  mounted() {
  },
  methods: {
    async getReceivedGoods(poItem, i) {
      if (this.inventoryClassId === null) {
        return;
      }

      let res;
      if (this.isUpcCode) {
        const readModel = {
          filters: {
            inventory_class_id: this.inventoryClassId,
            history_module_id_filter: this.historyModuleReceivingQrId,
            history_type_id_filter: this.historyTypeReplenishId,
            // history_record_type_id_filter: this.historyRecordTypeUpcCodeId,
            item_id_filter: poItem.inventory_item_id,
            po_number_filter: poItem.inventory_po.po_number,
            length_id_filter: poItem.length_id,
            uom_id_filter: poItem.uom_id,
          },
        };
        res = await TransactionsApi.readTransactions(readModel);
      } else if (this.isPaintClass) {
        const readModel = {
          filters: {
            inventory_class_id: this.inventoryClassId,
            history_module_id_filter: this.historyModuleReceivingQrId,
            history_type_id_filter: this.historyTypeCreateId,
            // history_type_id_filters_array: [this.historyTypeCreateId, this.historyTypeUpdateId],
            glass_paint_code_id_filter: poItem.glass_paint_code_id,
            po_number_filter: poItem.inventory_po.po_number,
            uom_id_filter: poItem.uom_id,
          },
        };
        res = await TransactionsApi.readTransactions(readModel);
      } else {
        const uomId = this.isGlassClass ? this.uomIdSheet : poItem.uom_id;
        const readModel = {
          filters: {
            inventory_class_id: poItem.inventory_item.inventory_class_id,
            inventory_item_id: poItem.inventory_item_id,
            po_number: poItem.inventory_po.po_number,
            length_id: poItem.length_id,
            uom_id: uomId,
            is_good_entrance: true,
          },
        };
        res = await this.$api.inventoryTag.read(readModel);
      }
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.$bus.$emit('showError', `Unable to retrieve received goods for ${poItem.item_full_name}`);
        return [];
      }
      if (res.data && res.data.length === 0) return [];
      let itemsToBeReceived = res.data;
      // if (this.isPaintClass) {
      //   itemsToBeReceived = this.findCreateTypeRecordsForMpqr(poItem.inventory_po.po_number, res.data);
      // }
      itemsToBeReceived = this.removeSubmittedGoodEntrances(poItem, itemsToBeReceived);
      itemsToBeReceived = this.attachProperties(poItem, itemsToBeReceived);

      return itemsToBeReceived;
    },

    removeDuplicates(poItems) {
      const seenIds = new Set();
      poItems.forEach(poItem => {
        poItem.goodEntrances.forEach(goodEntrance => {
          if (seenIds.has(goodEntrance.inventory_tag_id)) {
            return;
          } else {
            seenIds.add(goodEntrance.inventory_tag_id);
          }
        })
      })

      let uniqueItemsToBeReceived = this.itemsToBeReceived.filter(obj => {
        if (seenIds.has(obj.id)) {
          return false;
        } else {
          seenIds.add(obj.id);
          return true;
        }
      });

      return uniqueItemsToBeReceived;
    },

    // findCreateTypeRecordsForMpqr(poNumber, itemsToBeReceived) {
    //   // Function is broken - will not work if you mistakenly select the wrong po_number in RM Receiving and then update it to the correct one
    //   const updatedRecordsWithCurrentPoNumber = itemsToBeReceived.filter((item) => item.history.to_po_number === poNumber && item.history.from_po_number !== null && item.history.from_po_number !== poNumber && item.history.history_type_id === this.historyTypeUpdateId);
    //   const createRecords = [];
    //   if (updatedRecordsWithCurrentPoNumber.length > 0) {
    //     // O(updatedRecordsWithCurrentPoNumber * itemsToBeReceived)
    //     for (let i = 0; i < updatedRecordsWithCurrentPoNumber.length; i++) {
    //       const record = updatedRecordsWithCurrentPoNumber[i];

    //       for (let j = 0; j < itemsToBeReceived.length; j++) {
    //         const item = itemsToBeReceived[j];

    //         if (
    //           item.history.to_po_number === record.history.from_po_number &&
    //           item.history.inventory_tag === record.history.inventory_tag &&
    //           // item.history.quantity === record.history.quantity &&
    //           item.history.history_type_id === this.historyTypeCreateId
    //         ) {
    //           const foundItem = JSON.parse(JSON.stringify(item));
    //           foundItem.history.to_po_number = poNumber;
    //           createRecords.push(foundItem);
    //           itemsToBeReceived.splice(j, 1);
    //           break;
    //         }
    //       }
    //     }
    //   }
    //   itemsToBeReceived.forEach((item) => {
    //     if (item.history.from_po_number === null && item.history.to_po_number === poNumber && item.history.history_type_id === this.historyTypeCreateId) {
    //       createRecords.push(item);
    //     }
    //   });
    //   return createRecords;
    // },

    removeSubmittedGoodEntrances(poItem, itemsToBeReceived) {
      if (this.isUpcCode || this.isPaintClass) {
        const historyIds = poItem.goodEntrances.map(
          (goodEntrance) => goodEntrance.history_id,
        );

        const filteredItemsToBeReceived = itemsToBeReceived.filter(
          (itemToBeReceived) => !historyIds.includes(itemToBeReceived.history.id),
        );
        return filteredItemsToBeReceived;
      }
      const inventoryTags = poItem.goodEntrances.map(
        (goodEntrance) => goodEntrance.inventory_tag.inventory_tag,
      );

      const filteredItemsToBeReceived = itemsToBeReceived.filter(
        (itemToBeReceived) => !inventoryTags.includes(itemToBeReceived.inventory_tag),
      );
      return filteredItemsToBeReceived;
    },

    attachProperties(poItem, itemsToBeReceived) {
      itemsToBeReceived.forEach((itemToBeReceived) => {
        itemToBeReceived.inventory_po_item_id = poItem.id;
        itemToBeReceived.quantity_to_be_received = itemToBeReceived.quantity;
        if (this.isUpcCode || this.isPaintClass) {
          itemToBeReceived.po_number = itemToBeReceived.po_number; // not sure why this is here
          itemToBeReceived.quantity_to_be_received = itemToBeReceived.history.quantity_added;
        }
      });

      return itemsToBeReceived;
    },

    async submitGoodsReceived() {
      if (this.selectedItemsReceived.length <= 0) {
        this.$bus.$emit('showError', 'Please select items to receive');
        return;
      }

      const selectedItemsSet = new Set(this.selectedItemsReceived.map((item) => item.id));

      const items = [];

      this.itemsToBeReceived.forEach(item => {
        if (selectedItemsSet.has(item.id)) {
          const goodEntrance = {
            inventory_tag: this.isUpcCode || this.isPaintClass ? item.history.inventory_tag : item.inventory_tag,
            inventory_po_item_id: this.isRepaint ? null : item.inventory_po_item_id,
            inventory_item_id: item.inventory_item_id,
            history_id: this.isUpcCode || this.isPaintClass ? item.history.id : null,
            quantity: item.quantity_to_be_received,
            packaging_slip: item.packaging_slip,
            // is_repaint: this.isRepaint,
            uom_id: item.uom_id,
            length_id: item.length_id,
          };
          items.push(goodEntrance);
        }
      });

      const submitModel = {
        inventory_po_id: this.inventoryPoId,
        is_repaint: this.isRepaint,
        items: items,
      }

      const res = await this.$api.inventoryGoodEntrance.store(submitModel);
      if (!res || res.error) {
        this.$bus.$emit('showError', 'Error submitting goods received');
        return false;
      }

      const isSubmitAll = this.selectedItemsReceived.length === this.itemsToBeReceived.length;
      this.selectedItemsReceived = [];
      this.allItemsSelected = false;
      this.applyPackagingSlipNumber = '';
      this.$bus.$emit('showSuccess');
      this.$emit('goodsReceivedSubmitted', isSubmitAll);
    },

    toggleSelection(item) {
      const index = this.selectedItemsReceived.findIndex(
        (selected) => selected.id === item.id,
      );
      if (index > -1) {
        this.selectedItemsReceived.splice(index, 1); // Remove item from selection
      } else {
        this.selectedItemsReceived.push(item); // Add item to selection
      }
    },
    isSelected(item) {
      return this.selectedItemsReceived.some(
        (selected) => selected.id === item.id,
      );
    },
    toggleSelectAll() {
      if (this.allItemsSelected) {
        this.selectedItemsReceived = [];
      } else {
        this.selectedItemsReceived = this.itemsToBeReceived; // might've been better to just have selectItemsReceived to be a list of IDs and then on submit, go through the IDs and submit the items from this.itemsToBeReceived that have matching IDs
      }
      this.allItemsSelected = !this.allItemsSelected;
    },
    applyPackagingSlipNumberToAll() {
      this.itemsToBeReceived.forEach((item, index) => {
        this.$set(this.itemsToBeReceived, index, {
          ...item,
          packaging_slip: this.applyPackagingSlipNumber,
        });
      });
    },
  },
};
</script>

<style>
</style>
