<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="2"
        class="pb-5"
      >
        <v-select
          v-model="filterStatus"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          :loading="statusLoading"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pb-5"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobs"
          hide-details="true"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          item-value="id"
          :loading="jobLoading"
          clearable
          :single-select="false"
        >
          <template #selection="{ item }">
            <v-chip
              v-if="item.sub_class"
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.sub_class ? item.sub_class.color : 'white'"
            >
              {{ item && item.sub_class ? item.sub_class.name : '' }}
            </v-chip>
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
          <template #item="{ item }">
            <v-chip
              v-if="item.sub_class"
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.sub_class ? item.sub_class.color : 'white'"
            >
              {{ item && item.sub_class ? item.sub_class.name : '' }}
            </v-chip>
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="filterTruckType"
          label="Truck Type"
          :items="truckTypes"
          item-text="name"
          item-value="id"
          hide-details="true"
          :loading="truckTypeLoading"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols="1">
        <v-autocomplete
          v-model="filterTruck"
          label="Truck Number"
          :items="trucks"
          item-text="truck_number"
          item-value="id"
          hide-details="true"
          :loading="truckLoading"
          clearable
        />
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="mt-4 pb-0 pr-1"
      >
        <v-tooltip
          top
          color="black"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                color="success"
                outlined
                :disabled="selected.length <= 0"
                @click="runWebhookAll"
              >
                GENERATE PDF
              </v-btn>
            </div>
          </template>
          <span>Select At Least One Bill Of Lading</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="auto"
        class="mt-4 pb-0 pr-1"
      >
        <v-btn
          color="primary"
          outlined
          @click="openNewBillOfLading"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Bill Of Lading
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="mt-4 pb-0"
      >
        <v-btn
          color="error"
          outlined
          @click="openReturnWindow"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          Return Item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="pagination.itemsPerPage"
            :search="search"
            :loading="loading"
            show-select
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updatePagination"
          >
            <template #[`item.id`]="{ item }">
              <text-highlight :queries="search">
                {{
                  item && item.id
                    ? item.id
                    : '-'
                }}
              </text-highlight>
            </template>
            <template #[`item.job.code`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span><text-highlight :queries="search"> - {{ item.job_name ? item.job_name : '' }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.status.name`]="{ item }">
              <v-chip
                small
                :color="item && item.status ? item.status.color : ''"
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{ item && item.status ? item.status.name : '' }}
                </text-highlight>
              </v-chip>
            </template>
            <template #[`item.truck.name`]="{ item }">
              <v-chip
                v-if="item.truck && item.truck.type && item.truck.type.color && item.truck.type.name"
                small
                :color="
                  item.truck.type.color
                    ? item.truck.type.color
                    : 'grey'
                "
                text-color="white"
              >
                <text-highlight :queries="search">
                  {{
                    item.truck.type.name
                      ? item.truck.type.name
                      : ''
                  }}
                </text-highlight>
              </v-chip>
              <span v-else>-</span>
            </template>
            <template #[`item.truck.truck_number`]="{ item }">
              <text-highlight :queries="search">
                {{
                  item && item.truck && item.truck.truck_number
                    ? item.truck.truck_number
                    : '-'
                }}
              </text-highlight>
            </template>
            <template #[`item.truck.plate_number`]="{ item }">
              <text-highlight :queries="search">
                {{
                  item && item.truck && item.truck.plate_number
                    ? item.truck.plate_number
                    : '-'
                }}
              </text-highlight>
            </template>
            <template #[`item.total_item_quantity`]="{ item }">
              <text-highlight :queries="search">
                {{ item.total_item_qty ? item.total_item_qty : '-' }}
              </text-highlight>
            </template>
            <template #[`item.total_weight`]="{ item }">
              <text-highlight :queries="search">
                {{ item && item.total_weight ? `${item.total_weight.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}` : '-' }}
              </text-highlight>
            </template>
            <template #[`item.updated_at`]="{ item }">
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    <text-highlight :queries="search">
                      {{ item.updated_at ? formatDateTime(item.updated_at) : '' }}
                    </text-highlight>
                  </span>
                </template>
                <v-container style="min-width: 300px">
                  <v-row v-if="item.shipped_at">
                    <v-col class="pb-1 pt-0">
                      <span
                        style="color: white; font-size: 16px"
                      >Shipped At: </span><span
                        style="color: white; font-size: 16px"
                        class="pl-5"
                      >{{ formatDateTime(item.shipped_at) }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.shipped_by_full_name">
                    <v-col class="pb-1 pt-0">
                      <span
                        style="color: white; font-size: 16px"
                      >Shipped By:
                      </span>
                      <span
                        style="color: white; font-size: 16px"
                        class="pl-5"
                      >{{ item.shipped_by_full_name }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.received_at">
                    <v-col class="pb-1 pt-0">
                      <span
                        style="color: white; font-size: 16px"
                      >Received At: </span><span
                        style="color: white; font-size: 16px"
                        class="pl-5"
                      >{{ formatDateTime(item.received_at) }}</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.received_by_full_name">
                    <v-col class="pb-1 pt-0">
                      <span
                        style="color: white; font-size: 16px"
                      >Received By:
                      </span>
                      <span
                        style="color: white; font-size: 16px"
                        class="pl-5"
                      >{{ item.received_by_full_name }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tooltip>
            </template>
            <template #[`item.created_by`]="{ item }">
              <text-highlight :queries="search">
                {{ item.created_by_full_name ? item.created_by_full_name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                :color="
                  item.status_id === billOfLadingStatusIdShipped
                    ? 'success'
                    : 'purple'
                "
                outlined
                small
                @click="toggleReceiveStatus(item)"
              >
                {{
                  item.status_id === billOfLadingStatusIdShipped
                    ? 'RECEIVE'
                    : 'UNDO RECEIVE'
                }}
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="item.status_id === billOfLadingStatusIdShipped ? openBillOfLading(item, true) : openBillOfLading(item)"
              >
                {{
                  item.status_id === billOfLadingStatusIdShipped
                    ? 'EDIT'
                    : 'OPEN'
                }}
              </v-btn>
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    elevation="0"
                    fab
                    outlined
                    x-small
                    v-bind="attrs"
                    tile
                    color="primary"
                    style="
                      border-radius: 7px;
                      width: 28px !important;
                      height: 28px !important;
                    "
                    v-on="on"
                  >
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-3">
                  <v-list-item @click="runWebhook(item)">
                    <v-list-item-title
                      :style="`color: green; font-weight: 600;`"
                    >
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0"
                          style="white-space: nowrap"
                        >
                          <v-icon
                            style="font-size: 16px"
                            color="green"
                          >
                            fas fa-file
                          </v-icon>
                        </v-col>
                        <v-col class="pt-4">
                          PDF
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="softDelete(item)">
                    <v-list-item-title :style="`color: red; font-weight: 600;`">
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="pr-0"
                          style="white-space: nowrap"
                        />
                        <v-col class="pt-4">
                          DELETE
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <bill-of-lading-form
      ref="billOfLadingForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import BillOfLadingForm from '@/components/forms/billOfLadings/BillOfLadingForm.vue';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    BillOfLadingForm,
  },
  data: () => ({
    items: [],
    truckTypes: [],
    trucks: [],
    statuses: [],
    jobs: [],
    selected: [],

    topSpaceHeight: 310,
    windowHeight: 1000,
    headers: [
      { text: 'BOL', value: 'code', sortable: true },
      { text: 'Job Ref', value: 'job.code', sortable: true },
      {
        text: 'Status', value: 'status.name', align: 'center', sortable: true,
      },
      { text: 'Truck Type', value: 'truck.name', sortable: true },
      { text: 'Truck Number', value: 'truck.truck_number', sortable: true },
      { text: 'Plate Number', value: 'truck.plate_number', sortable: true },
      { text: 'Qty', value: 'total_item_qty', sortable: true },
      {
        text: 'Total Weight (kg)', value: 'total_weight', align: 'end', sortable: true,
      },
      { text: 'Updated At', value: 'updated_at', sortable: true },
      { text: 'Created By', value: 'created_by', sortable: true },
      {
        text: 'Actions', value: 'actions', align: 'end', sortable: false,
      },
    ],
    pagination: {
      itemsPerPage: 100,
      page: 1,
      sortBy: 'bill_of_ladings.id',
      sortDesc: true,
    },
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    total: 0,

    isLoading: false,
    statusLoading: false,
    jobLoading: false,
    truckTypeLoading: false,
    truckLoading: false,

    returnItem: true,
    billOfLadingStatusIdShipped: 1,
    billOfLadingStatusIdReceived: 2,

    filterTruckType: null,
    filterTruck: null,
    filterStatus: null,
    filterJob: null,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
  },
  watch: {
    search() {
      this.get();
    },
    pagination() {
      this.get();
    },
    filterStatus() {
      this.get();
    },
    filterJob() {
      this.get();
    },
    filterTruckType() {
      this.get();
    },
    filterTruck() {
      this.get();
    },
  },
  created() {
    this.init();
    this.roles = this.user.roles.map((x) => x.id);
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          status_id: this.filterStatus,
          job_id: this.filterJob,
          truck_type_id: this.filterTruckType,
          truck_schedule_truck_id: this.filterTruck,
        },
        search: this.search,
        pagination: this.pagination,
      };
      const res = await this.$api.billOfLading.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;

      if (this.statuses.length === 0) {
        this.getStatuses();
      }
      if (this.truckTypes.length === 0) {
        this.getTruckTypes();
      }
      if (this.trucks.length === 0) {
        this.getTrucks();
      }
      if (this.jobs.length === 0) {
        this.getJobs();
      }
    },

    async getStatuses() {
      this.statusLoading = true;
      this.statuses = await this.$api.billOfLadingStatus.get();
      this.statusLoading = false;
    },

    async getJobs() {
      this.jobLoading = true;
      const submitModel = {
        filters: {
          // inventory_class_id: this.inventoryClassId,
          // is_sub_class: 1
        },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) {
        this.jobLoading = false;
        return;
      }
      this.jobs = res;
      this.jobLoading = false;
    },

    async getTruckTypes() {
      this.truckTypeLoading = true;
      const submitModel = {
        filters: {
        },
      };
      this.truckTypes = await this.$api.truckScheduleTruckType.read(submitModel);
      this.truckTypeLoading = false;
    },

    async getTrucks() {
      this.truckLoading = true;
      this.trucks = await this.$api.truckScheduleTruck.get();
      this.truckLoading = false;
    },

    openNewBillOfLading() {
      this.$refs.billOfLadingForm.open();
    },

    openBillOfLading(item, edit = false) {
      if (edit) {
        this.$refs.billOfLadingForm.open(item.id);
      } else {
        this.$refs.billOfLadingForm.open(item.id, null, null, true);
      }
    },

    openReturnWindow() {
      this.$refs.billOfLadingForm.open(null, this.returnItem);
    },

    closeForm() {
      this.returnItem = false;
    },

    success() {
      this.get();
    },

    async toggleReceiveStatus(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure you want to ${
          item.status_id === this.billOfLadingStatusIdShipped
            ? 'receive'
            : 'undo receive of'
        } Bill Of Lading #${item.id}?`,
      });
      if (!confirm) return;
      const res = await this.$api.billOfLading.get(`toggle/${item.id}`);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to delete Bill Of Lading #${item.id}?`,
      });
      if (!confirm) return;
      const res = await this.$api.billOfLading.delete(`${item.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    updatePagination(newOptions) {
      if (newOptions.page !== this.pagination.page || newOptions.itemsPerPage !== this.pagination.itemsPerPage || newOptions.sortBy !== this.pagination.sortBy || newOptions.sortDesc !== this.pagination.sortDesc) {
        this.pagination = newOptions;
      }
    },

    formatDateTime(dateTime) {
      return FormatHelpers.convertDateTimeFormat(dateTime);
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async runWebhook(item) {
      this.$bus.$emit('loading');
      const webhookModuleIdBillOfLading = 49;
      const webhookData = {
        module_id: webhookModuleIdBillOfLading,
        id: item.id,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.code}_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async runWebhookAll() {
      this.$bus.$emit('loading');
      const webhookModuleIdBillOfLading = 50;
      const ids = this.selected.map((x) => x.code);
      if (ids.length === 0) {
        this.$bus.$emit('showError', 'At least one Bill Of Lading must be selected');
        return;
      }
      const idsString = ids.join(',');

      const webhookData = {
        module_id: webhookModuleIdBillOfLading,
        ids: idsString,
      };

      const res = await this.$api.webhook.store(webhookData, 'run-download', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Bills_of_Lading_${now}`;

      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },
  },
};
</script>
