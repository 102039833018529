<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    submit-btn-text="Create"
    :full-screen="true"
    :warning-text="'*Red ones are still to be created'"
    :submit-btn-disabled="disableSubmit"
  >
    <template slot="header">
      <span>Create Auto Batch</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="2"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="inventoryClassId"
            :is-add-all="false"
            :is-required="true"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="filterJob"
            label="Job"
            :items="jobCodes"
            hide-details="true"
            item-value="code"
            clearable
            :loading="jobsLoading"
          >
            <template #selection="{ item }">
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>

            <template #item="{ item }">
              <span>
                <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <v-autocomplete
            v-model="poNumber"
            :items="poNumbers"
            hide-details="true"
            :disabled="!inventoryClassId"
            :rules="[(v) => !!v || 'PO# is required']"
            :loading="poLoading"
          >
            <template slot="label">
              PO#<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="groupedBatches"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
            dense
          >
            <template #[`item.inventory_class_id`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <span
                :style="`font-size: 14px !important; color: ${item.id ? 'black' : 'red'}`"
                class="font-weight-bold"
              >

                {{ item.batch_number ? item.batch_number : '' }}
              </span>
            </template>
            <template #[`item.po_number`]="{ item }">
              {{ item.po_number }}
            </template>
            <template #[`item.g_code`]="{ item }">
              {{ item.g_code }}
            </template>
            <template #[`item.glass_thickness`]="{ item }">
              {{ item.glass_thickness }}
            </template>
            <template #[`item.total_glass`]="{ item }">
              {{ item.total_glass }}
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;">{{ item.job_code ? `${item.job_code}` : '' }}</span> - {{ item.job_name ? item.job_name : item.job_name }} <span style="color: #1976D2;">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</span>
              </span>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                v-if="item.id"
                small
                :color="item.status ? item.status.color : ''"
                text-color="white"
              >
                {{ item.status ? item.status.name : '' }}
              </v-chip>
              <v-chip
                v-else
                small
                color="error"
                text-color="white"
              >
                TO BE CREATED
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                :disabled="item.id ? true : false"
                @click="removeBatch(item.batch_number)"
              >
                CANCEL BATCH
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="poNumber && total">
        <v-spacer />
        <v-col cols="3">
          <span :style="{ color: isDarkMode ? '' : '#000000', fontSize: '18px' }">Total: <span style="font-size: 20px; font-weight: 500;">{{ total }}</span></span>
        </v-col>
      </v-row>
      <confirm-form ref="confirmForm" />
    </template>
  </the-dialog-box>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

export default {
  data: () => ({
    groupedBatches: [],
    poNumbers: [],
    jobCodes: [],
    headers: [
      { text: 'Class', value: 'inventory_class_id' },
      { text: 'Job', value: 'job' },
      { text: 'PO#', value: 'po_number' },
      { text: 'Batch#', value: 'batch_number' },
      { text: 'Glass Code', value: 'g_code' },
      { text: 'Glass Thickness', value: 'glass_thickness' },
      { text: 'Glass Qty', value: 'total_glass' },
      { text: 'Max Glass Qty', value: 'max_total_glass' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    loading: false,
    poNumber: null,
    filterJob: null,
    inventoryClassId: null,
    total: 0,
    jobsLoading: false,
    poLoading: false,
    disableSubmit: false,
  }),
  computed: {
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
  },
  watch: {
    inventoryClassId() {
      this.getPoItems();
      this.getJobs();
    },
    filterJob() {
      this.getPoItems();
    },
    poNumber() {
      this.getGroupedBatches();
    },
  },
  created() {
  },
  methods: {

    async getGroupedBatches() {
      if (!this.poNumber || !this.inventoryClassId) return;
      this.loading = true;
      const submitModel = { po_number: this.poNumber, inventory_class_id: this.inventoryClassId };
      const res = await this.$api.glassBatch.store(submitModel, 'grouped');
      if (!res) return;
      this.groupedBatches = res;
      this.total = res.reduce((acc, curr) => {
        const value = parseInt(curr.total_glass, 10);
        return Number.isInteger(value) ? acc + value : acc;
      }, 0);
      this.loading = false;
    },

    async getPoItems() {
      this.poLoading = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId, is_not_include_with_batch: 1, job_code: this.filterJob },
      };
      const res = await this.$api.glass.readPoGroupedSecondary(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res) {
        this.poNumbers = [];
        this.poLoading = false;
        return;
      }
      this.poNumbers = res.filter((x) => x.status_id !== 4).map((x) => x.po_number);
      this.poLoading = false;
    },

    async getJobs() {
      this.jobsLoading = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
      };
      const res = await this.$api.job.readDataSecondary(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        this.jobsLoading = false;
        return;
      }
      this.jobCodes = res;
      this.jobsLoading = false;
    },

    async removeBatch(name) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record from Batch?',
      });
      if (!confirm) return;
      const itemIndex = this.groupedBatches.findIndex((x) => x.batch_number === name);
      if (itemIndex === -1) return;

      this.groupedBatches.splice(itemIndex, 1);
    },

    async submit() {
      this.disableSubmit = true;
      if (!this.poNumber || !this.inventoryClassId) {
        this.$bus.$emit('showError', 'PO# and Inventory Class are required!');
        this.disableSubmit = false;
        return false;
      }
      if (this.groupedBatches.length === 0) {
        this.$bus.$emit('showError', 'There are no Glass items to add to a Batch!');
        this.disableSubmit = false;
        return false;
      }

      this.$bus.$emit('loading');
      const userId = this.$store.getters.user.id;
      // remove existing batches
      const newBatches = this.groupedBatches.filter((x) => !x.id);
      const batches = newBatches.map((x) => {
        const y = { ...x };
        y.created_by = userId;
        return y;
      });
      const submitModel = { batches };
      const res = await this.$api.glassBatch.store(submitModel);
      this.$bus.$emit('loading-stop');

      if (!res) {
        this.$bus.$emit('showError');
        this.disableSubmit = false;
        return false;
      }

      this.disableSubmit = false;
      this.$emit('success');
      return res;
    },

    open(inventoryClassId, poNumber) {
      this.$refs.dialogBox.open();
      this.groupedBatches = [];
      if (inventoryClassId && poNumber) {
        this.inventoryClassId = inventoryClassId;
        this.poNumber = poNumber;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}
</style>
